import {
  Container,
  Typography,
  TextField,
  Button,
  Checkbox,
  Box,
  FormGroup,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
} from "@mui/material";
import HeaderComponent from "../components/HeaderComponent";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import Services from "../services/Services";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Language from "../languages";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

export default function CreateNewPostPage() {
  const projectName = useSelector((state) => state.projectName);
  const storedMall = localStorage.getItem("mall");
  const parsedMall = JSON.parse(storedMall);
  const mall = useSelector((state) => state.mall);
  const language = useSelector((state) => state.language);
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const item = location.state;
  const token = localStorage.getItem("token");
  const uri = localStorage.getItem("mallUri");
  const [count, setCount] = useState();
  const [success, setSuccess] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [failed, setFailed] = useState(false);
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [loadingReject, setLoadingReject] = useState(false);
  const [checkbox1, setCheckbox1] = useState(false);
  const [checkbox2, setCheckbox2] = useState(false);
  const [checkbox3, setCheckbox3] = useState(false);
  const [hidePrice, setHidePrice] = useState(false);
  const [euroRate, setEuroRate] = useState(35);
  const euro = 5;
  const tl = 175;
  const dollar = 6;

  const [totalPrice, setTotalPrice] = useState("");
  const [currency, setCurrency] = useState("");
  const [deliverToText, setDeliverToText] = useState("");
  const [additionalNote, setAdditionalNote] = useState("");

  const padWithZero = (value) => {
    return value < 10 ? `0${value}` : `${value}`;
  };
  const getCurrentDate = () => {
    const currentDate = new Date();
    const day = padWithZero(currentDate.getDate());
    const month = padWithZero(currentDate.getMonth() + 1);
    const year = currentDate.getFullYear();
    return `${day}.${month}.${year}`;
  };
  const deadlineDate = getCurrentDate();
  const deadlineTime = "22:00";

  const confirm = () => {
    // // console.log(item?.customerID, item?.orderID, token);
    if (parsedMall[0].paymentType === "online-payment") {
      navigate("/package-summary", { state: item });
    } else {
      setLoadingConfirm(true);
      Services.CustomerOrderConfirm(token, item?.customerID, item?.orderID)
        .then((res) => {
          // // console.log(res.data);
          if (res.data.status) {
            // window.alert("");
            // navigate("/");
            setSuccess(true);
          } else {
            setFailed(true);
          }
        })
        .catch((err) => {
          setFailed(true);
        })
        .finally(() => {
          setLoadingConfirm(false);
        });
    }
  };

  const reject = () => {
    // // console.log(item.customerID, item.orderID);
    setLoadingReject(true);
    Services.CustomerOrderReject(token, item.customerID, item.orderID)
      .then((res) => {
        // // console.log(res.data);
        if (res.data.status) {
          // window.alert("Paketler iptal edilmiştir, iyi alışverişler dileriz.");
          // navigate("/");
          setRejectModal(true);
        } else {
          setFailed(true);
        }
      })
      .catch((err) => {
        setFailed(true);
      })
      .finally(() => {
        setLoadingReject(false);
      });
  };

  useEffect(() => {
    console.log("Çalıştı");
    console.log("mall: ", mall);
  }, []);

  useEffect(() => {
    if (
      parsedMall[0].paymentType === "mall-pay" ||
      parsedMall[0].calculationUnit === "free"
    ) {
      setHidePrice(true);
    }

    Services.CustomerPosts(token, item?.customerID)
      .then((res) => {
        const filteredPosts = res.data.result.filter(
          (post) => post.orderID === item.orderID
        );
        console.log(JSON.parse(storedMall)[0]);
        console.log(filteredPosts);

        let price = filteredPosts[0].deliveryTypeInfo.price;
        let totalPrice = 0;

        if (JSON.parse(storedMall)[0].calculationUnit === "service") {
          totalPrice = price;
        } else if (
          JSON.parse(storedMall)[0].calculationUnit === "per-package"
        ) {
          totalPrice = price * filteredPosts.length;
        }

        setTotalPrice(totalPrice);
        setCurrency(filteredPosts[0].deliveryTypeInfo.currency);
        setDeliverToText(filteredPosts[0].deliveryTypeInfo.text);
        setAdditionalNote(filteredPosts[0].deliveryTypeInfo.additionalNote);

        setCount(filteredPosts);
      })
      .catch((error) => {
        console.error("Error fetching posts:", error);
      });

    // Services.GetExchangeRate()
    //   .then((res) => {
    //     console.log(res.data);
    //     // const rate = res.data.rates.TRY;
    //     const euroCurrency = res.data.TCMB_AnlikKurBilgileri.find(
    //       (currency) => currency.Isim === "EURO"
    //     );
    //     if (euroCurrency) {
    //       setEuroRate(euroCurrency.BanknoteSelling);
    //     }
    //   })
    //   .catch((error) => {
    //     console.error("Error fetching exchange rate:", error);
    //   });
  }, [token, item?.customerID, item?.orderID]);

  return (
    <Container>
      <Box sx={{ my: 4, mx: 2 }}>
        <HeaderComponent
          left="back"
          // middle={t("createPost")}
          middle={Language(mall, language, "createPost")}
          goBack={() => navigate(`/${uri}`)}
          right="icon"
        />
        <Box sx={{ marginTop: 5 }}>
          {/* <Typography
            variant="h6"
            textAlign="center"
            sx={{ lineHeight: "16.94px", fontSize: 14, fontWeight: 700 }}
          >
            Aşağıdaki Paketler
          </Typography>
          <Typography
            variant="h6"
            textAlign="center"
            sx={{
              lineHeight: "16.94px",
              fontSize: 14,
              fontWeight: 700,
              marginBottom: 5,
            }}
          >
            Easy Point Galataport'a teslim edilecek
          </Typography> */}
          <Typography
            variant="h6"
            textAlign="center"
            sx={{
              lineHeight: "16.94px",
              fontSize: 14,
              fontWeight: 700,
              marginBottom: 5,
            }}
          >
            {/* {t("pendingPackages")} */}
            {Language(mall, language, "pendingPackages")}
          </Typography>
          {count?.map((order) => (
            <TextField
              id="outlined-basic"
              label={`${order?.orderNumber}`}
              variant="outlined"
              fullWidth
              disabled
              InputLabelProps={{
                style: { color: "black", fontSize: 15, fontWeight: "bold" },
              }}
              sx={{
                marginBottom: 2,
                backgroundColor: "#ffffff",
                borderColor: "#C1C6C9",
                borderWidth: 2, // Increase the border width
                "& .MuiOutlinedInput-notchedOutline": {
                  borderWidth: 2, // Increase the border width for outlined variant
                },
              }}
            />
          ))}

          {mall === "galataport" && (
            <Typography
              variant="subtitle1"
              sx={{
                marginBlock: 2,
                lineHeight: "19.49px",
                color: "gray",
                fontSize: 13,
                fontWeight: 600,
              }}
            >
              {language === "tr"
                ? `*Fiyat Bilgisi: Hizmet bedeli poşet başına ${euro} euro veya ${dollar} dolar veya ${tl} tl olarak ücretlendirilmiştir.`
                : `*Price Information: The service fee is charged as ${euro} euros or ${dollar} dollars or ${tl} TL per bag.`}
            </Typography>
          )}

          <Divider />

          <Typography
            sx={{
              marginBlock: 2,
              lineHeight: "19.49px",
              fontSize: 13,
            }}
          >
            <span style={{ fontWeight: "600" }}>
              {Language(mall, language, "deliveryAddress")}:
            </span>{" "}
            {deliverToText}
          </Typography>

          <Typography
            sx={{
              marginBlock: 2,
              lineHeight: "19.49px",
              fontSize: 13,
            }}
          >
            <span style={{ fontWeight: "600" }}>
              {Language(mall, language, "additionalNote")}:
            </span>{" "}
            {additionalNote}
          </Typography>

          <Divider sx={{ marginBottom: 2 }} />
          <FormGroup fullWidth>
            {/* <FormControlLabel
              control={
                <Checkbox
                  {...label}
                  defaultChecked
                  color="newRed"
                  checked={checkbox1}
                  onChange={() => setCheckbox1(!checkbox1)}
                />
              }
              label={
                <>
                  {" "}
                  {language === "tr" ? (
                    <Typography
                      variant="subtitle1"
                      sx={{
                        lineHeight: "19.49px",
                        fontSize: 13,
                        fontWeight: 600,
                      }}
                    >
                      <span
                        style={{
                          color: "#FF6E60",
                          textDecoration: "underline",
                        }}
                      >
                        Mesafeli satış sözleşmesini
                      </span>{" "}
                      onaylıyorum.
                    </Typography>
                  ) : (
                    <Typography
                      variant="subtitle1"
                      sx={{
                        lineHeight: "19.49px",
                        fontSize: 13,
                        fontWeight: 600,
                      }}
                    >
                      I agree to the{" "}
                      <span
                        style={{
                          color: "#FF6E60",
                          textDecoration: "underline",
                        }}
                      >
                        distance sales contract.
                      </span>
                    </Typography>
                  )}
                </>
              }
              sx={{ marginBottom: 2 }}
            /> */}

            <FormControlLabel
              control={
                <Checkbox
                  {...label}
                  defaultChecked
                  color="newRed"
                  checked={checkbox3}
                  onChange={() => setCheckbox3(!checkbox3)}
                />
              }
              label={
                <Typography
                  variant="subtitle1"
                  sx={{ lineHeight: "19.49px", fontSize: 13, fontWeight: 600 }}
                >
                  {uri === "the-land-of-legends"
                    ? Language(mall, language, "timeLimitLoL")
                    : Language(mall, language, "timeLimit")}
                </Typography>
              }
              sx={{ marginBottom: 2 }}
            />

            {!hidePrice && (
              <FormControlLabel
                control={
                  <Checkbox
                    {...label}
                    defaultChecked
                    color="newRed"
                    checked={checkbox2}
                    onChange={() => setCheckbox2(!checkbox2)}
                  />
                }
                label={
                  <Typography
                    variant="subtitle1"
                    sx={{
                      lineHeight: "19.49px",
                      fontSize: 13,
                      fontWeight: 600,
                    }}
                  >
                    {language === "tr"
                      ? `Toplam ${totalPrice} ${currency} gönderi ücretini
                    onaylıyorum.`
                      : `I agree to a total service fee of ${totalPrice} ${currency}`}
                    {/* {language === "tr"
                      ? `Toplam ${euro * count?.length} € (${Math.round(
                          euroRate * euro * count?.length
                        )} ₺) gönderi ücretini onaylıyorum.`
                      : `I agree to a total shipping fee of ${
                          euro * count?.length
                        } € (${Math.round(euroRate * euro * count?.length)} ₺)`} */}
                  </Typography>
                }
                sx={{ marginBottom: 15 }}
              />
            )}
          </FormGroup>

          <Button
            variant="contained"
            color="inherit"
            disabled={loadingReject || loadingConfirm}
            fullWidth
            sx={{ paddingY: 1.2, color: "black" }}
            onClick={reject}
          >
            {/* {loadingReject ? `${t("canceling")}` : `${t("cancel")}`} */}
            {loadingReject
              ? Language(mall, language, "canceling")
              : Language(mall, language, "cancel")}
          </Button>
          <Button
            variant="contained"
            color="newRed"
            disabled={
              loadingReject ||
              loadingConfirm ||
              !checkbox3 ||
              (!hidePrice && !checkbox2)
            }
            fullWidth
            sx={{ paddingY: 1.2, marginTop: 3, fontWeight: "600" }}
            onClick={confirm}
          >
            {/* {loadingConfirm ? `${t("beingCreated")}` : `${t("create")}`} */}
            {loadingConfirm ? (
              Language(mall, language, "beingCreated")
            ) : JSON.parse(storedMall)[0].paymentType === "online-payment" ? (
              <>{Language(mall, language, "confirmAndPay")}</>
            ) : (
              <>{Language(mall, language, "confirmAndCreate")}</>
            )}
          </Button>
        </Box>
      </Box>

      <Dialog open={success}>
        <DialogTitle color={"green"}>
          {Language(mall, language, "success")}
        </DialogTitle>
        <DialogContent>
          <Typography>
            {Language(mall, language, "confirmSuccessMessage")}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setSuccess(false);
              navigate(`/${uri}`);
            }}
            color="primary"
          >
            {Language(mall, language, "okay")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={rejectModal}>
        <DialogTitle color={"Green"}>
          {Language(mall, language, "success")}
        </DialogTitle>
        <DialogContent>
          <Typography>
            {Language(mall, language, "rejectSuccessMessage")}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setRejectModal(false);
              navigate(`/${uri}`);
            }}
            color="primary"
          >
            {Language(mall, language, "okay")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={failed} onClose={() => setFailed(false)}>
        <DialogTitle color={"red"}>
          {Language(mall, language, "error")}
        </DialogTitle>
        <DialogContent>
          <Typography>{Language(mall, language, "errorMessage")}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setFailed(false)} color="primary">
            {Language(mall, language, "close")}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
